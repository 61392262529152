// src/components/TopBar.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import TextSearch from '../../components/TextSearch/TextSearch';
import { Coins } from 'iconoir-react'; // Import icons

const TopBar = ({ onSearch, projects = [] }) => {
  const { user, subscriptionData } = useAuth();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]); // Define state for search results

  const handleNavigate = (path) => {
    navigate(path);
  };
  const handleOnSearch = (string) => {
    const filteredProjects = projects.filter(project =>
      project.name.toLowerCase().includes(string.toLowerCase())
    );
    setSearchResults(filteredProjects);
  };

  const handleOnSelect = (item) => {
    const selectedProject = projects.filter(project => project.id === item.id);
    setSearchResults(selectedProject);
  };

  const planTitle = subscriptionData?.data?.length > 0 && subscriptionData?.data?.[0]?.title
    ? subscriptionData?.data?.[0]?.title
    : "FREE PLAN";

  return (
    <header className="flex justify-between items-center p-4 bg-white">
      <p className="text-2xl pr-12 text-custom-topBlue font-extrabold">ailms.co Dashboard</p>
      <div className="flex-1 flex justify-center w-1/3">
        <TextSearch
          items={searchResults.map(project => ({ id: project.project_id, name: project.name }))}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          placeholder="Search for templates"
        />
      </div>
      <div className="flex items-center">
        <div className="flex items-center ml-12 cursor-pointer gap-2"
          onClick={() => handleNavigate("/profile")}>
          <img
            src={user?.avatar ? `https://backend.ailms.co${user?.avatar}` : <div className="w-8 h-8 rounded-full bg-gray-300"></div>}
            alt="Profile"
            className="w-8 h-8 rounded-full border-[1px] border-grey"
          />
          <div className="flex flex-col">
            <span className="text-sm mb-[2px] font-bold">{user?.username}</span>
            <span className="text-xxs text-grey uppercase">{planTitle}</span>
          </div>
          <div className="flex items-start gap-2">
            <Coins className="w-5 h-5 text-yellow-500" />
            <div className="flex items-baseline gap-1">
              <span className="text-sm font-semibold text-gray-800">{user?.credits}</span>
              <span className="text-xs text-gray-500">Credits</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopBar;
