import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { api } from "../../api/api";
import { useAuth } from "../../context/AuthContext";

import { UserCircle, CreditCard, Coins } from 'iconoir-react'; // Import icons
import stripeService from '../../utils/stripeService';

const AccountInfo = () => {
  const { user, setAuthState } = useAuth();
  const [avatar, setAvatar] = useState(null);
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [userData] = useState({});
  const [location, setLocation] = useState(user.location || ''); // Add state for location
  const [about, setAbout] = useState(user.about || ''); // Add state for about

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatar(e.target.result); // Update the avatar preview
      };
      reader.readAsDataURL(file);
      setProfilePictureFile(file); // Store the file object for upload
    }
  };

  const handleSave = async () => {
    try {
      let avatarUrl = userData.avatar;

      if (profilePictureFile) {
        const formData = new FormData();
        formData.append('files', profilePictureFile); // Use 'files' as the key for Strapi

        const uploadResponse = await api.post('/api/upload', formData);
        console.log('PictureUrl', uploadResponse[0].url);
        if (uploadResponse && uploadResponse.length > 0) {
          avatarUrl = uploadResponse[0].url; // Extract the URL from the response
        } else {
          console.log("Failed to upload profile picture");
        }
      }

      const updatedData = {
        avatar: avatarUrl,
        location, // Include location in the updated data
        about, // Include about in the updated data
      };

      const response = await api.put(`/api/users/${user.id}`, updatedData)
      setAuthState(prev => ({
        ...prev,
        user: {
          ...prev.user,
          avatar: response.avatar,
        }
      }))
      console.log("User data updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  return (
    <div>
      <h2>Your Account</h2>
      <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            value={user.username}
            onChange={(e) => console.log(e.target.value)} // Example usage
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={user.email}
            disabled // Add this attribute to disable the input field
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">User ID</label>
          <input
            type="text"
            value={user.user_id}
            disabled // Add this attribute to disable the input field
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Location</label>
          <input
            type="text"
            value={location} // Use state variable for location
            onChange={(e) => setLocation(e.target.value)} // Update state on change
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700">About</label>
          <textarea
            value={about} // Use state variable for about
            onChange={(e) => setAbout(e.target.value)} // Update state on change
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
            rows="4"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700">Avatar</label>
          <input
            type="file"
            onChange={handleProfilePictureChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
          />
          {avatar && (
            <img src={avatar} alt="Avatar Preview" className="mt-4 h-20 w-20 rounded-full" />
          )}
        </div>
      </form>
      <button className="bg-blue-500 mt-4 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600" onClick={handleSave}>Save</button>
    </div>
  );
};

// const LoginSecurity = () => {
//   const [currentPassword, setCurrentPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');

//   const handleChangePassword = (e) => {
//     e.preventDefault();
//     // Add logic to handle password change
//     console.log('Password change submitted');
//   };

//   return (
//     <div>
//       <h2>Login & Security</h2>
//       <form onSubmit={handleChangePassword} className="bg-white p-6 rounded shadow">
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">Current Password</label>
//           <input
//             type="password"
//             value={currentPassword}
//             onChange={(e) => setCurrentPassword(e.target.value)}
//             className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">New Password</label>
//           <input
//             type="password"
//             value={newPassword}
//             onChange={(e) => setNewPassword(e.target.value)}
//             className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
//           <input
//             type="password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
//           />
//         </div>
//         <button
//           type="submit"
//           className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600"
//         >
//           Change Password
//         </button>
//       </form>
//     </div>
//   );
// };

// const PrivacySettings = () => {
//   const [notifications, setNotifications] = useState(false);
//   const [newsletter, setNewsletter] = useState(false);
//   const [privateProfile, setPrivateProfile] = useState(false);

//   return (
//     <div>
//       <h2>Privacy Settings</h2>
//       <div className="bg-white p-6 rounded shadow">
//         <div className="flex items-center justify-between mb-4">
//           <span>Notifications</span>
//           <input
//             type="checkbox"
//             checked={notifications}
//             onChange={() => setNotifications(!notifications)}
//             className="toggle-switch"
//           />
//         </div>
//         <div className="flex items-center justify-between mb-4">
//           <span>Newsletter Subscription</span>
//           <input
//             type="checkbox"
//             checked={newsletter}
//             onChange={() => setNewsletter(!newsletter)}
//             className="toggle-switch"
//           />
//         </div>
//         <div className="flex items-center justify-between mb-4">
//           <span>Private Profile</span>
//           <input
//             type="checkbox"
//             checked={privateProfile}
//             onChange={() => setPrivateProfile(!privateProfile)}
//             className="toggle-switch"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

//Billing & Payment Section 

const BillingPayment = () => {
  const { subscriptionData } = useAuth();

  const handleManageSubscription = async () => {
    try {
      const session = 'https://billing.stripe.com/p/login/6oEaHV89YgaO3JK8ww';
      console.log("Customer session created:", session);
      window.location.href = session;
    } catch (error) {
      console.error("Error creating customer session:", error);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Billing & Payment</h2>
      <div className="bg-white p-6 rounded shadow">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 mb-4"
          onClick={handleManageSubscription}
        >
          Manage Subscription
        </button>
        {subscriptionData?.data?.length > 0 ? (
          <table className="w-full text-sm border border-gray-300 text-left text-white dark:text-white">
            <thead className="text-xs text-white uppercase bg-custom-blue">
              <tr>
                <th className="px-4 py-2">Plan</th>
                <th className="px-4 py-2">Storage</th>
                <th className="px-4 py-2">Amount</th>
                <th className="px-4 py-2">Currency</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Next Billing Date</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscriptionData?.data?.map((sub, index) => (
                <tr
                  key={sub.id}
                  className={`bg-white border-b text-black ${index % 2 === 0 ? 'bg-light-blue-100' : ''}`}
                >
                  <td className="px-4 py-2">{sub?.title || 'N/A'}</td>
                  <td className="px-4 py-2">{sub?.storageGB || 'N/A'} GB</td>
                  <td className="px-4 py-2">{sub?.plan?.amount / 100}</td>
                  <td className="px-4 py-2">{sub?.price?.currency?.toUpperCase()}</td>
                  <td className={`px-4 py-2 uppercase ${sub.status === 'active' ? 'text-green-500' : ''}`}>
                    {sub.status}
                  </td>
                  <td className="px-4 py-2">
                    {new Date(sub.nextBillingDate).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      className="bg-green-500 text-white px-2 py-1 rounded-md shadow-sm hover:bg-green-600"
                      onClick={() => console.log('Generate Invoice for', sub.id)}
                    >
                      Generate Invoice
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No active subscriptions found.</p>
        )}
      </div>
    </div>
  );
};

const AddCredit = () => {
  const { user } = useAuth();
  const [creditOptions, setCreditOptions] = useState([]);
  const [selectedCreditsPlan, setSelectedCreditsPlan] = useState(null);
  const [message, setMessage] = useState('');

  const handleFetchCreditOptionsFromStripe = async () => {
    try {
      const { creditsPlans } = await stripeService.getCreditAndSubscriptionsPlans()

      setCreditOptions(creditsPlans.sort((a, b) => a.metadata.credits - b.metadata.credits));
    } catch (error) {
      console.error('Error fetching credit options:', error);
    }
  }

  const handleAddCredits = async () => {
    if (selectedCreditsPlan) {
      const responseURL = await stripeService.createCheckoutSessionForCredits({
        productId: selectedCreditsPlan.id,
        successUrl: 'https://ailms.co/profile',
        cancelUrl: 'https://ailms.co/profile',
        customerEmail: user.email,
      });
      window.location.href = responseURL;
    } else {
      setMessage('Please select a credit package.');
    }
  };

  useEffect(() => {
    handleFetchCreditOptionsFromStripe();
  }, [])

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Add Credits</h2>
      <p className="mb-6">Current credits balance: {user?.credits}</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {creditOptions?.map((option) => {
          const credits = option?.metadata?.credits;
          const price = option?.prices?.[0].unit_amount / 100;

          return (
            <div
              key={option.id}
              className={`p-4 cursor-pointer transition-all hover:shadow-lg border 
                ${credits && selectedCreditsPlan?.metadata?.credits === credits
                ? 'border-blue-500 bg-blue-100'
                : 'border-gray-300'
                }`}
              onClick={() => setSelectedCreditsPlan(option)}
            >
              <div className="space-y-4">
                <h3 className="text-lg font-bold text-center">
                  {credits} Créditos
                </h3>
                <div className="text-center space-y-2">
                  <p className="text-2xl font-bold text-gray-900">
                    {price}€
                  </p>
                  <p className="text-sm text-gray-600">
                    {option.description}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="mt-6 text-center">
        <button
          onClick={handleAddCredits}
          className="bg-blue-500 text-white px-6 py-2 rounded-md shadow-sm hover:bg-blue-600 disabled:bg-gray-300"
          disabled={!selectedCreditsPlan}
        >
          Add Credits
        </button>
        {message && (
          <p className={`mt-4 text-sm ${message.includes('Redirecting') ? 'text-green-500' : 'text-red-500'}`}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('account-info');

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <TopBar />
        <div className="flex-1 flex bg-custom-skyBlue p-4 overflow-auto">
          <aside className="w-1/4 p-4">
            <nav>
              <ul>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'account-info' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('account-info')}
                  >
                    <UserCircle className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Your Account</span>
                  </button>
                </li>
                {/* <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'login-security' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('login-security')}
                  >
                    <Lock className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Login & Security</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'privacy-settings' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('privacy-settings')}
                  >
                    <ShieldCheck className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Privacy Settings</span>
                  </button>
                </li> */}
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'billing-payment' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('billing-payment')}
                  >
                    <CreditCard className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Billing & Payment</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-left p-2 flex items-center space-x-2 ${activeTab === 'add-credit' ? 'bg-gray-200' : ''}`}
                    onClick={() => setActiveTab('add-credit')}
                  >
                    <Coins className="w-6 h-6" />
                    <span className="text-sm mb-1 font-bold">Add credits</span>
                  </button>
                </li>
                {/* Add more buttons as needed */}
              </ul>
            </nav>
          </aside>

          <main className="flex-1 bg-white p-4 overflow-auto">
            {activeTab === 'account-info' && <AccountInfo />}
            {/* {activeTab === 'login-security' && <LoginSecurity />}
            {activeTab === 'privacy-settings' && <PrivacySettings />} */}
            {activeTab === 'billing-payment' && <BillingPayment />}
            {activeTab === 'add-credit' && <AddCredit />}
            {/* Add more conditional renders as needed */}
          </main>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;