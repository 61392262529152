// src/components/Sidebar.jsx
import React from "react";
import { useNavigate } from 'react-router-dom';
import { Home, Folder, UserLove, CartPlus, Plus, Learning, LogOut } from "iconoir-react"; // Import LogOut icon
import { useAuth } from "../../context/AuthContext";
import { displayInfoMessage } from "../../utils/helpers";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout, subscriptionData } = useAuth();
  const { projects } = useSelector((state) => state.projects) || { projects: [] };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <aside className="w-20 bg-custom-topBlue text-white text-xs flex flex-col items-center justify-between py-4">
      <div className="flex flex-col items-center">
        <div
          className="p-2 flex flex-col items-center cursor-pointer"
          onClick={() => {
            if (subscriptionData.isActive === false && projects.length >= 1) {
              return displayInfoMessage("You have reached the limit of projects for the free plan. Please upgrade to create more projects.", "warning");
            }
            navigate('/add-new-project')}}
        >
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-r from-red-500 to-blue-500">
            <Plus className="w-6 h-6 text-white" />
          </div>
          <span>Add New</span>
        </div>
      </div>
        <div
          className="p-2 rounded-md hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer m-2"
          onClick={() => navigate('/dashboard')}
        >
          <Home className="w-6 h-6 hover:text-custom-dark" />
          <span>Home</span>
        </div>
        <div
          className="p-2 rounded-md hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer m-2"
          onClick={() => navigate('/my-files')}
        >
          <Folder className="w-6 h-6 hover:text-custom-dark" />
          <span>My Files</span>
        </div>
        <div
          className="p-2 rounded-md hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer m-2"
          onClick={() => navigate('/your-lms')}
        >
          <Learning className="w-6 h-6 hover:text-custom-dark" />
          <span>LMS</span>
        </div>
        <div
          className="p-2 rounded-md hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer m-2"
          onClick={() => navigate('/intra-marketplace')}
        >
          <CartPlus className="w-6 h-6 hover:text-custom-dark" />
          <span>Market</span>
        </div>
        <div
          className="p-2 rounded-md hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer m-2"
          onClick={() => navigate('/profile')}
        >
          <UserLove className="w-6 h-6 hover:text-custom-dark" />
          <span>Profile</span>
        </div>
      
      <hr className="w-full border-t border-gray-300 my-2" /> {/* Separator line */}
      <div
        className="p-2 rounded-md m-2 hover:bg-custom-skyBlue hover:text-custom-dark flex flex-col items-center cursor-pointer"
        onClick={handleLogout}
      >
        <LogOut className="w-6 h-6 hover:text-custom-dark" />
        <span>Log Out</span>
      </div>
    </aside>
  );
};

export default Sidebar;