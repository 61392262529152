import React, { useEffect, useCallback, useState} from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { api } from "../../api/api";
import { deleteProject, setProjects } from "../../store/reducers/projectListSlice";
import moment from "moment/moment";
import { useAlert } from "../../context/AlertContext";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import { Edit, Eye, Learning, Plus, MoreVert } from "iconoir-react";
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { createSCORMFilesv2, S3_BUCKET_NAME, displayInfoMessage } from "../../utils/helpers";
import addFolderToPublicAccessResource from "../DashboardContainer/AddPublicPolicyS3";



const ListOfProject = () => {
  const { user, subscriptionData } = useAuth();
  const { projects } = useSelector((state) => state.projects, shallowEqual) || { projects: [] };
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [searchTerm, setSearchTerm] = useState('');
  
  const fetchProjects = useCallback(async () => {
    if (!user?.user_id) return;

    try {
      const response = await api.get(`/api/projects?filters[user_id]=${user.user_id}`);
      if (response.data) {
        // Assuming the API response includes a 'deployed' field for each project
        dispatch(setProjects(response.data));
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  }, [dispatch, user?.user_id]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  

  const deployScormPackage = async (projectId) => {
    try {
      // Find the project data 
      const project = projects.find(p => p.project_id === projectId);
      console.log("project", project);
      
      // Get the organization id from the user
      const organization = await api.get(`/api/users/${user.id}?populate=organizations`);
      console.log("organization", organization);

      // Check if the user has an organization
      if (!organization.organizations || organization.organizations.length === 0) {
        displayInfoMessage("User doesn't have an organization. Create organizations first before proceeding to the next step.", 'error');
        return;
      }

      // Check if the course is already published
      const existingCourseResponse = await api.get(`/api/courses?filters[project_id][$eq]=${projectId}&filters[status][$eq]=published`);
      const existingCourse = existingCourseResponse.data && existingCourseResponse.data[0];

      // Determine the new version number
      const currentVersion = existingCourse?.attributes?.version || "1.0";
      const newVersion = (parseFloat(currentVersion) + 0.1).toFixed(1);

      // Update the project status to deployed
      await api.put(`/api/projects/${projectId}`, {
        data: {
          deployed: true,
          deployedAt: new Date().toISOString()
        }
      });

      const courseData = {
        data: {
          title: existingCourse?.attributes?.title || project.name,
          description: existingCourse?.attributes?.description || null,
          cover: existingCourse?.attributes?.cover || project.project_image?.url,
          project_id: projectId,
          organization: organization.organizations[0].id,
          course_url: `https://${S3_BUCKET_NAME}.s3.amazonaws.com/${user.user_id}/LMS_projects/${projectId}/index.html` || null,
          status: "published",
          version: newVersion // Use the new version here
        }
      };

      if (existingCourse) {
        // Update existing course
        const response = await api.put(`/api/courses/${existingCourse.id}`, courseData);
        console.log("Course updated", response);
      } else {
        // Create a new course
        const response = await api.post(`/api/courses`, courseData);
        console.log("Course created", response);
      }

      // Add public access to the folder
      await addFolderToPublicAccessResource(S3_BUCKET_NAME, user.user_id, projectId);

      // Create SCORM files
      await createSCORMFilesv2(projectId, project.name, true, user.user_id);

      console.log("SCORM package deployed successfully");
      navigate(`/your-lms`);
    } catch (error) {
      console.error("Error deploying SCORM package:", error);
      showAlert("error", "Failed to deploy SCORM package.");
    }
  };

  const downloadScormPackage = async (projectId) => {
    const project = projects.find(p => p.project_id === projectId);
    try {
      // Directly call createSCORMFiles with the projectId
      await createSCORMFilesv2(projectId, project.name, false, user.user_id);
  
      console.log("SCORM package created successfully");
      
    } catch (error) {
      console.error("Error downloading SCORM package:", error);
      showAlert("error", "Failed to download SCORM package.");
    }
  };
  

  
  const navigateToEditor = (projectId) => {
    window.open(`/editor/${projectId}`, '_self');
    console.log("Navigating to editor:", projectId);
  };

  const deleteProjects = async (projectId) => {
    try {
      const deleteResponse = await api.delete(`/api/projects/${projectId}`);
      if (deleteResponse) {
        showAlert("success", "Project deleted successfully!");
        dispatch(deleteProject(projectId));
      }
    } catch (error) {
      showAlert(
        "error",
        error?.response?.data?.error?.message || "Error in deleting a project"
      );
    }
  };

  const handleViewHistory = (projectId) => {
    navigate(`/project-history/${projectId}`);
  };

  const toggleMenu = (projectId) => {
    setMenuOpen((prev) => (prev === projectId ? null : projectId));
  };

  const handleLMSButtonClick = (project) => {
    if (project.deployed) {
      setSelectedProjectId(project.project_id);
      setIsModalOpen(true);
    } else {
      deployScormPackage(project.project_id);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProjectId(null);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    const filtered = projects.filter(project => 
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  }, [searchTerm, projects]);

  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold mb-4">Warning</h2>
            <p className="mb-4">This project is already deployed. You want to override ? User progress will be lost.</p>
            <button
              className="bg-red-500 text-white p-2 rounded"
              onClick={() => {
                deployScormPackage(selectedProjectId);
                closeModal();
              }}
            >
              Override Deployment
            </button>
            <button
              className="bg-gray-300 text-black p-2 rounded ml-2"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <div className="flex h-screen bg-custom-skyBlue">
        <Sidebar />

        <div className="flex-1 flex flex-col">
          <TopBar onSearch={handleSearch} projects={filteredProjects} />

          <main className="flex-1 bg-custom-skyBlue p-4 overflow-auto">
            <div className="bg-white p-6 rounded shadow">
              <img src="/dashboardBanner.png" alt="Dashboard Banner" className="w-full h-auto mb-6" />
              <div className="flex justify-end space-x-2 mb-6">
                <button
                  className="bg-blue-500 text-white p-2 px-12 rounded flex items-center"
                  onClick={() => {
                    if (subscriptionData.isActive === false && projects.length >= 1) {
                      return displayInfoMessage("You have reached the limit of projects for the free plan. Please upgrade to create more projects.", "warning");
                    }
                    navigate('/add-new-project')
                  }}
                >
                  <Plus className="mr-2" />
                  Create New Project
                </button>
              </div>
              <h3 className="text-lg font-semibold mb-2">Recent Projects</h3>
              {projects.length > 0 ? (
                <div className="grid grid-cols-4 gap-4">
                  {projects.map((project) => (
                    <div key={project.project_id} className="bg-white p-4 rounded shadow">
                      <img
                        src={project.project_image?.url ? `https://backend.ailms.co${project.project_image.url}` : "/logo512.png"}
                        alt={project.name}
                        className="w-full h-32 object-cover rounded"
                      />
                      <div className="flex justify-between items-center mt-2">
                        <h4 className="font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                          {project.name}
                        </h4>
                        <div className="relative">
                          <button onClick={() => toggleMenu(project.project_id)}>
                            <MoreVert className="w-5 h-5 font-bold" />
                          </button>
                          {menuOpen === project.project_id && (
                            <div className="absolute top-8 right-0 bg-white shadow-md rounded p-2">
                              <button
                                className="text-xxs text-gray-700 hover:text-blue-500"
                                onClick={() => handleViewHistory(project.project_id)}
                              >
                                Versions
                              </button>
                              <button
                                className="text-xxs text-gray-700 hover:text-red-500 mt-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  deleteProjects(project?.project_id);
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="text-xxs text-gray-700 hover:text-blue-500 mt-1"
                                onClick={() => downloadScormPackage(project.project_id)}
                              >
                                Download
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="text-xxs text-gray-500">
                        {moment(project.updatedAt).isValid() 
                          ? moment(project.updatedAt).format("MMM Do YYYY") 
                          : moment(project.publishedAt).format("MMM Do YYYY")}
                      </p>
                      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-2">
                        <button
                          className="bg-gray-200 p-2 rounded flex items-center justify-center hover:bg-blue-400"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigateToEditor(project?.project_id);
                          }}
                        >
                          <Edit className="w-4 h-4 mr-1" />
                          <span className="text-xs">Edit</span>
                        </button>
                        <button
                          className={`bg-gray-200 p-2 rounded flex items-center justify-center hover:bg-blue-400}`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleLMSButtonClick(project);
                          }}
                        >
                          <Learning className="w-4 h-4 mr-1" />
                          <span className="text-xs">LMS</span>
                        </button>
                        <button
                          className="bg-gray-200 p-2 rounded flex items-center justify-center hover:bg-blue-400"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(`/edit-project/${project?.project_id}`);
                          }}
                        >
                          <Eye className="w-4 h-4 mr-1" />
                          <span className="text-xs">View</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-center text-gray-500">No projects available.</p>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ListOfProject;
