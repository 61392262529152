import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faWandMagicSparkles} from "@fortawesome/free-solid-svg-icons";
import { generateAudioWithAI } from "../../../utils/aiGenerationService";
import { useDispatch } from "react-redux";
import { addNewAudio } from "../../../store/reducers/imageBucketSlice";
import AudioFile from "../../../utils/audioFile";

const AIAudioControl = ({handleAudioUpload}) => {
    const [inputTextAreaVisibility, setInputTextAreaVisibility] = useState(false);
    const [inputPrompt, setInputPrompt] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleChangeInputPrompt = (event) => {
        const newValue = event.target.value;
        setInputPrompt(newValue);
    };

    const handleGenerateResponse = async () => {
        try {
            setLoading(true);
            const audioURL = await generateAudioWithAI(inputPrompt);
            dispatch(addNewAudio(new AudioFile(audioURL)))
        } catch (error) {
            console.error("Error generating response:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <label htmlFor="fontSelector" className="mb-2 block text-xs font-medium text-gray-600">Generate AI
                Audio</label>
            <div className="mb-6 flex flex-col items-center text-gray-600">
                {inputTextAreaVisibility && (
                    <textarea
                        id="fontSelect"
                        className="flex h-20 mb-4 w-full rounded-md border-2 border-gray-200 bg-gray-100 p-2 text-xs"
                        onChange={handleChangeInputPrompt}
                        value={inputPrompt}
                        placeholder="Enter text prompt.."
                    />
                )}
                {!inputTextAreaVisibility && (
                    <button
                        className="flex h-10 w-full flex-grow items-center justify-center rounded-md border bg-white text-xs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                        onClick={() => {
                            setInputTextAreaVisibility(!inputTextAreaVisibility);
                        }}
                    >
                        <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2 text-gray-600"/>
                        Generate using AI
                    </button>
                )}
                {inputTextAreaVisibility && (
                    <button
                        className="flex h-10 w-full flex-grow items-center justify-center rounded-md border bg-white text-xs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                        onClick={handleGenerateResponse}
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin text-gray-600"/>{" "}
                                Generating...
                            </>
                        ) : (
                            "Generate"
                        )}
                    </button>
                )}
            </div>
        </div>
    )
}
export default AIAudioControl;