import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faWandMagicSparkles} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  centerFabricObject,
  getFabricImageFromURL,
  handleLoader,
} from "../../../utils/helpers";
import { generateImageWithAI } from "../../../utils/aiGenerationService";
import { addNewImage } from "../../../store/reducers/imageBucketSlice";
import imageFile from "../../../utils/imageFile";

const AIImageControl = () => {

  const [inputTextAreaVisibility, setInputTextAreaVisibility] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { canvas } = useSelector(
        (state) => state.fabricCanvas
    );

  const handleChangeInputPrompt = (event) => {
    const newValue = event.target.value;
    setInputPrompt(newValue);
  };

  const handleGenerateResponse = async () => {
    try {
      setLoading(true);
      const imageUrl = await generateImageWithAI(inputPrompt);

      dispatch(addNewImage(new imageFile(imageUrl)));
      
      setLoading(false);
      handleLoader(true, "load_image_on_canvas", dispatch);
      
      getFabricImageFromURL(imageUrl, (fabricImage) => {
        fabricImage.set({
          borderWidth: 0,
          borderStyle: 'none',
          borderFill: "rgb(0,0,0)",
          listStyle: 'none',
          link: '',
          jumpToSlide: 0,
        });
        fabricImage.setControlsVisibility({
          mt: false,
          mb: false,
        });
        fabricImage.scaleToWidth(200);
        canvas.add(fabricImage);
        canvas.setActiveObject(fabricImage);
        centerFabricObject(fabricImage, canvas);
        canvas.renderAll();
        handleLoader(false, '', dispatch);
      });

      canvas.renderAll();
    } catch (error) {
      console.error("Error generating response:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <label htmlFor="fontSelector" className="mb-2 block text-xxs font-medium text-gray-600">Generate AI
                Image</label>
      <div className="mb-6 flex flex-col items-center text-gray-600">
        {inputTextAreaVisibility && (
          <textarea
            id="fontSelect"
            className="flex h-20 min-h-8 mb-4 w-full rounded-md border-2 border-gray-200 bg-gray-100 p-2 text-xxs"
            onChange={handleChangeInputPrompt}
            value={inputPrompt}
            placeholder="Enter text prompt.."
          />
        )}
        {!inputTextAreaVisibility && (
          <button
            className="flex h-10 w-full flex-grow items-center justify-center rounded-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
            onClick={() => {
              setInputTextAreaVisibility(!inputTextAreaVisibility);
            }}
          >
            <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2 text-gray-600"/>
            Generate using AI
          </button>
        )}
        {inputTextAreaVisibility && (
          <button
            className="flex h-10 w-full flex-grow items-center justify-center rounded-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
            onClick={handleGenerateResponse}
            disabled={loading}
          >
            {loading ? (
              <>
                <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin text-gray-600"/>{" "}
                Generating...
              </>
            ) : (
              "Generate"
            )}
          </button>
        )}
      </div>
    </div>
  )
}
export default AIImageControl;