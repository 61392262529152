import { api } from "../api/api";
import { displayInfoMessage } from "./helpers";

export const generateTextWithAI = async (prompt) => {
    try {
        const response = await api.post(`/api/ai-generation/generate-text`, {
            prompt
        }, false);

        return response.generatedText;
    } catch (error) {
        const errorMessage = error?.response?.data?.error?.message || error?.error || "Error generating text with AI";
        displayInfoMessage(`${errorMessage}`, "danger")
        console.error('Error generating text with AI:', error);
        throw error;
    }
}

export const generateImageWithAI = async (prompt) => {
    try {
        const response = await api.post(`/api/ai-generation/generate-image`, {
            prompt
        }, false);

        return response.imageUrl;
    } catch (error) {
        const errorMessage = error?.response?.data?.error?.message || error?.error || "Error generating image with AI";
        displayInfoMessage(`${errorMessage}`, "danger")
        console.error('Error generating image with AI:', error);
        throw error;
    }
}

export const generateAudioWithAI = async (prompt) => {
    try {
        const response = await api.post(`/api/ai-generation/generate-audio`, {
            prompt
        }, false);

        return response.audioData;
    } catch (error) {
        const errorMessage = error?.response?.data?.error?.message || error?.error || "Error generating audio with AI";
        displayInfoMessage(`${errorMessage}`, "danger")
        throw error;
    }
}