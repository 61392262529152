import { api } from '../api/api';

// Define your base URL if it's different from the frontend URL
//const API_BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:1337/api';

const stripeService = {
  async createCustomerPortalSession(customerId) {
    try {
      // Make an API call to your Strapi backend to create a portal session
      const response = await api.post('/api/create-checkout-session', {
        customer: customerId,
        return_url: 'https://ailms.co/dashboard', // Replace with your actual return URL
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.STRIPE_SECRET_KEY}`, // Replace with your actual secret key
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    
      // Return the portal session URL if successful
      return response.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
      throw error;
    }
  },

  // body should contain the following fields: productId, customerEmail, successUrl, cancelUrl
  async createCheckoutSessionForCredits(body) {
    try {
      // Make an API call to your Strapi backend to create a portal session
      const response = await api.post('/api/create-checkout-session-for-credits', body, {
        headers: {
          'Authorization': `Bearer ${process.env.STRIPE_SECRET_KEY}`, // Replace with your actual secret key
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    
      // Return the portal session URL if successful
      return response.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
      throw error;
    }
  },

  // body should contain the following fields: productId, priceId, customerEmail, successUrl, cancelUrl
  async createCheckoutSessionForSubscriptions(body) {
    try {
      // Make an API call to your Strapi backend to create a portal session
      const response = await api.post('/api/create-checkout-session-for-subscriptions', body, {
        headers: {
          'Authorization': `Bearer ${process.env.STRIPE_SECRET_KEY}`, // Replace with your actual secret key
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    
      // Return the portal session URL if successful
      return response.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
      throw error;
    }
  },
  async getCreditAndSubscriptionsPlans() {
    try {
      // Make an API call to your Strapi backend to get the credit and subscription plans
      const response = await api.get('/api/stripe/get-credit-and-subscription-plans');

      // Returns creditsPlans, oneTimeProducts and subscriptions
      return response;
    } catch (error) {
      console.error('Error getting credit and subscription plans:', error);
      throw error;
    }
  }
};

export default stripeService;
