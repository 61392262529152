import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useAuth } from "../../context/AuthContext";

const MyFiles = ({ userId }) => {
  const { user, subscriptionData } = useAuth();
  const [files, setFiles] = useState([]);
  const [storageUsed, setStorageUsed] = useState(0);
  const [groupedFiles, setGroupedFiles] = useState({});
  const bucketName = 'slidesdeck';
  const [totalStorage, setTotalStorage] = useState(0);


  useEffect(() => {
    const calculateTotalStorage = async () => {
      if (subscriptionData?.isActive && subscriptionData?.data && subscriptionData?.data.length > 0) {
        const storageGB = subscriptionData?.data[0]?.product?.storageGB || 'NA';
        return setTotalStorage(storageGB * 1024 * 1024 * 1024);
      }
      return setTotalStorage(0)
    };
    calculateTotalStorage();
  }, [subscriptionData, user]);

  useEffect(() => {
    if (!user || !user.user_id) return;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();

    const fetchFiles = async () => {
      try {
        const params = {
          Bucket: bucketName,
          Prefix: `${user.user_id}`,
        };
        const data = await s3.listObjectsV2(params).promise();

        setFiles(data.Contents);

        // Group files by their folder names (e.g., image_list, video_list, LMS_projects)
        const grouped = data.Contents.reduce((acc, file) => {
          const folderParts = file.Key.split('/');
          const folder = folderParts[1]; // First level folder (image_list, video_list, LMS_projects)

          // Special handling for LMS_projects to display only UUIDs (second level folder)
          if (folder === 'LMS_projects' && folderParts.length > 2) {
            const uuid = folderParts[2]; // Assuming UUID is at the second level
            if (!acc[folder]) {
              acc[folder] = new Set(); // Use Set to avoid duplicate UUIDs
            }
            acc[folder].add(uuid); // Add UUID, not the individual files
          } else {
            if (!acc[folder]) {
              acc[folder] = [];
            }
            acc[folder].push(file); // Push the file normally for other folders
          }

          return acc;
        }, {});

        // Convert Set back to array for easier rendering in LMS_projects
        if (grouped.LMS_projects) {
          grouped.LMS_projects = Array.from(grouped.LMS_projects);
        }

        setGroupedFiles(grouped);

        const totalSize = data.Contents.reduce((acc, file) => acc + file.Size, 0);
        setStorageUsed(totalSize);
      } catch (error) {
        console.error('Error fetching files from S3:', error);
      }
    };

    fetchFiles();
  }, [user, bucketName]);

  const storagePercentage = (storageUsed / Math.max(1, totalStorage)) * 100;
  const storageUsedMB = (storageUsed / (1024 * 1024)).toFixed(2);

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <TopBar />
        <main className="flex-1 bg-custom-skyBlue p-4 overflow-auto">
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-lg font-semibold mb-2">My Files</h3>
            <div className="w-full bg-gray-200 rounded-full h-4 mb-4 relative">
              <div
                className="bg-blue-600 h-4 rounded-full flex items-center text-black text-xs absolute left-0"
                style={{ width: `${storagePercentage}%` }}
              >
                <span className="px-2 whitespace-nowrap">
                  {storagePercentage.toFixed(2)}% ({storageUsedMB} MB)
                </span>
              </div>
            </div>
            <div className="flex justify-between text-sm text-gray-700 mt-2">
              <span>Total Storage: {(totalStorage / (1024 * 1024 * 1024)).toFixed(2)} GB</span>
              <span>Available Storage: {((totalStorage - storageUsed) / (1024 * 1024 * 1024)).toFixed(2)} GB</span>
            </div>
            <div className="relative overflow-x-auto">
              {Object.keys(groupedFiles).map((folder) => (
                <div key={folder} className="mb-4">
                  <h4 className="font-bold text-lg mb-2">{folder}</h4>

                  {groupedFiles[folder].length > 0 ? (
                    folder === 'LMS_projects' ? (
                      <table className="w-full text-sm border border-gray-300 text-left text-white dark:text-white">
                        <thead className="text-xs text-white uppercase bg-custom-blue">
                          <tr>
                            <th scope="col" className="px-6 py-3">Project Title</th>
                            <th scope="col" className="px-6 py-3">UUID</th>
                            <th scope="col" className="px-6 py-3">Size (MB)</th>
                            <th scope="col" className="px-6 py-3">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedFiles[folder].map((uuid, index) => {
                            const folderFiles = files.filter(file => file.Key.includes(uuid));
                            const totalSize = folderFiles.reduce((acc, file) => acc + file.Size, 0);
                            const projectTitle = `Project Title for ${uuid}`; // Replace with actual logic to get project title
                            return (
                              <tr
                                key={uuid}
                                className={`bg-white border-b text-black ${index % 2 === 0 ? 'bg-light-blue-100' : ''}`}
                              >
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis dark:text-black max-w-xs">
                                  {projectTitle}
                                </td>
                                <td className="px-6 py-4 text-xxs">{uuid}</td>
                                <td className="px-6 py-4">{(totalSize / (1024 * 1024)).toFixed(2)} MB</td>
                                <td className="px-6 py-4">LMS-Project</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <table className="w-full text-sm border border-gray-300 text-left text-white dark:text-white">
                        <thead className="text-xs text-white uppercase bg-custom-blue">
                          <tr>
                            <th scope="col" className="px-6 py-3">Name</th>
                            <th scope="col" className="px-6 py-3">Size (MB)</th>
                            <th scope="col" className="px-6 py-3">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedFiles[folder].map((file, index) => (
                            <tr
                              key={file.Key}
                              className={`bg-white border-b text-black ${index % 2 === 0 ? 'bg-light-blue-100' : ''}`}
                            >
                              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis dark:text-black max-w-xs">
                                <a
                                  href={`https://${bucketName}.s3.amazonaws.com/${file.Key}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-600 dark:text-blue-500 hover:underline"
                                >
                                  {file.Key.split('/').pop()}
                                </a>
                              </td>
                              <td className="px-6 py-4">{(file.Size / (1024 * 1024)).toFixed(2)} MB</td>
                              <td className="px-6 py-4">{file.Key.split('.').pop()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                  ) : (
                    <p className="text-gray-500">No files available in this category.</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MyFiles;
