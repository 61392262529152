import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSpinner, faWandMagicSparkles} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generateTextWithAI } from "../../../utils/aiGenerationService";

const AITextControl = () => {
  const [inputTextAreaVisibility, setInputTextAreaVisibility] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  
  const { activeObject, canvas } = useSelector(
    (state) => state.fabricCanvas
);

  const handleChangeInputPrompt = (event) => {
    const newValue = event.target.value;
    setInputPrompt(newValue);
  };

  const handleGenerateResponse = async () => {
    try {
      setLoading(true);
      const response = await generateTextWithAI(inputPrompt);
      setGeneratedText(response);
      setTypingIndex(0);
      setLoading(false);
    } catch (error) {
      console.error("Error generating response:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (generatedText) {
      const typingTimer = setInterval(() => {
        if (typingIndex <= generatedText.length) {
          setDisplayText(generatedText.substring(0, typingIndex));
          setTypingIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(typingTimer);
        }
      }, 50);
      return () => clearInterval(typingTimer);
    }
  }, [generatedText, typingIndex]);
  useEffect(() => {
    if (displayText?.length > 0 && activeObject && canvas) {
      activeObject.set({
        text: displayText,
      });
      canvas.renderAll();
    }
  }, [displayText, activeObject, canvas]);

  return (
    <div>
      <label htmlFor="fontSelector" className="mb-2 block text-xxs font-medium text-gray-600">Generate AI
                Text</label>
      <div className="mb-6 flex flex-col items-center text-gray-600">
        {inputTextAreaVisibility && (
          <textarea
            id="fontSelect"
            className="flex h-20 mb-4 w-full rounded-md border-2 border-gray-200 bg-gray-100 p-2 text-xxs"
            onChange={handleChangeInputPrompt}
            value={inputPrompt}
            placeholder="Enter text prompt.."
          />
        )}
        {!inputTextAreaVisibility && (
          <button
            className="flex h-10 w-full flex-grow items-center justify-center rounded-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
            onClick={() => {
              setInputTextAreaVisibility(!inputTextAreaVisibility);
            }}
          >
            <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2 text-gray-600"/>
            Generate using AI
          </button>
        )}
        {inputTextAreaVisibility && (
          <button
            className="flex h-10 w-full flex-grow items-center justify-center rounded-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
            onClick={handleGenerateResponse}
            disabled={loading}
          >
            {loading ? (
              <>
                <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin text-gray-600"/>{" "}
                Generating...
              </>
            ) : (
              "Generate"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default AITextControl;
