import React, { useEffect } from "react";
import { TickMarkIcon } from "../../../utils/iconHelper";
import { useAuth } from "../../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import stripeService from "../../../utils/stripeService";
import { useState } from "react";

const Payment = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedSubscriptionTime, setSelectedSubscriptionTime] =
    useState("month");

  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleFetchSubscriptionPlans = async () => {
    try {
      const { subscriptions } =
        await stripeService.getCreditAndSubscriptionsPlans();

      setSubscriptionPlans(
        subscriptions?.sort(
          (a, b) => a.prices?.[1]?.unit_amount - b.prices?.[1]?.unit_amount
        )
      );
    } catch (error) {
      console.error("Error getting credit and subscription plans:", error);
      throw error;
    }
  };

  const handleSubscriptionCheckout = async ({ productId, priceId }) => {
    const responseURL =
      await stripeService.createCheckoutSessionForSubscriptions({
        productId,
        priceId,
        customerEmail: user?.email,
        successUrl: "https://ailms.co/dashboard",
        cancelUrl: "https://ailms.co/subscription-details",
      });
    window.location.href = responseURL;
  };

  const selectedCorrectPrice = (prices) => {
    return prices.find(
      (price) => price.recurring.interval === selectedSubscriptionTime
    );
  };

  useEffect(() => {
    handleFetchSubscriptionPlans();
  }, []);

  return (
    <>
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-12 lg:px-6">
        <div className="max-w-screen-md mx-auto mb-8 text-center">
          <p className="text-md text-blue-600 mb-4">PRICING</p>
          <button
            className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center SS_ProductCheckout"
            onClick={handleLogout}
          >
            Logout
          </button>
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-700 ">
            Designed for business teams like yours
          </h2>
          <p className="mb-5  text-gray-500 sm:text-xl ">
            Here at Landwind we focus on markets where technology, innovation,
            and capital can unlock long-term value and drive economic growth.
          </p>
        </div>
        <div className="flex items-center gap-4 justify-center lg:mb-8">
          <div className="w-16 text-right">
            <span
              className={`text-sm font-medium ${
                selectedSubscriptionTime === "month"
                  ? "text-gray-900"
                  : "text-gray-500"
              }`}
            >
              Monthly
            </span>
          </div>

          <button
            onClick={() =>
              setSelectedSubscriptionTime(
                selectedSubscriptionTime === "month" ? "year" : "month"
              )
            }
            className={`relative inline-flex h-6 w-11 shrink-0 items-center rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
              selectedSubscriptionTime === "year"
                ? "bg-indigo-600"
                : "bg-gray-200"
            }`}
          >
            <span className="sr-only">Toggle subscription time</span>
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out ${
                selectedSubscriptionTime === "year"
                  ? "translate-x-6"
                  : "translate-x-1"
              }`}
            />
          </button>

          <div className="w-16">
            <span
              className={`text-sm font-medium ${
                selectedSubscriptionTime === "year"
                  ? "text-gray-900"
                  : "text-gray-500"
              }`}
            >
              Yearly
            </span>
          </div>

          <div className="w-28">
            <span className="text-xs font-medium text-green-600 bg-green-100 px-2 py-1 rounded-full whitespace-nowrap">
              {selectedSubscriptionTime === "year"
                ? "20% off year"
                : "Save 20% year"}
            </span>
          </div>
        </div>

        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 mb-6">
          {subscriptionPlans?.map((plan) => {
            return (
              <div className="flex flex-col max-w-lg w-full p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow  xl:p-8">
                <h3 className="mb-4 text-2xl font-semibold">{plan.name}</h3>
                <p className="font-light text-gray-500 sm:text-lg ">
                  {plan.description}
                </p>
                <div className="flex items-baseline justify-center my-8">
                  <span className="mr-2 text-5xl font-extrabold">
                    $
                    {parseInt(selectedCorrectPrice(plan.prices)?.unit_amount) /
                      100}
                  </span>
                  <span className="text-gray-500 ">
                    /{selectedSubscriptionTime}
                  </span>
                </div>

                <ul className="mb-8 space-y-4 text-left">
                  {Object.entries(plan?.metadata)
                    ?.filter(([key, value]) => key.includes("feature"))
                    ?.map((feature) => {
                      return (
                        <li className="flex items-center space-x-3">
                          <TickMarkIcon />
                          <span>{feature[1]}</span>
                        </li>
                      );
                    })}
                </ul>
                <button
                  className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center SS_ProductCheckout"
                  onClick={() =>
                    handleSubscriptionCheckout({
                      productId: plan.id,
                      priceId: selectedCorrectPrice(plan.prices)?.id,
                    })
                  }
                >
                  Get started
                </button>
              </div>
            );
          })}
        </div>
        {/* Free Plan */}
        <Link
          to={"/dashboard"}
          className="flex flex-col items-center justify-end px-6 py-4 text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300"
        >
          <span className="text-lg font-bold">Continue for FREE</span>
        </Link>
      </div>
    </>
  );
};

export default Payment;
