import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

function ProtectedRoute({ element }) {
  const { isAuthenticated, user, subscriptionData } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    const path = location.pathname;

    const verifyAccess = async () => {
      if (!isAuthenticated && !localStorage.getItem('token')) {
        navigate('/access-denied');
        setLoading(false);
        return;
      }

      if (path.includes('/editor') || path === '/dashboard' || path === '/my-files' || path === '/profile' || path === '/your-lms' || path === '/intra-marketplace') {
        try {
          if (user && subscriptionData) {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          navigate('/access-denied');
        }
      } else {
        setLoading(false);
      }
    };

    verifyAccess();
  }, [isAuthenticated, user, location.pathname, navigate, subscriptionData]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return element;
}

export default ProtectedRoute;
